<template>
	<div class="bigBox">
		<h2>客户列表</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="公司名称/行业名称"
								class="namePut"></el-input>
							<el-button type="primary" icon="Search" class="searchs" @click="customerSearch">
								搜索</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table :data="tableUserInfoList" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column label="客户ID" align="center" prop="id">
					</el-table-column>
					<el-table-column prop="company_name" label="公司名称" align="center">
					</el-table-column>
					<el-table-column prop="trade_name" label="行业名称" align="center">
					</el-table-column>
					<el-table-column prop="contacts" label="联系人" align="center">
					</el-table-column>
					<el-table-column prop="mobile" label="电话" align="center">
					</el-table-column>
					<el-table-column prop="address" label="地址" align="center">
					</el-table-column>
					<el-table-column prop="email" label="邮箱" align="center">
					</el-table-column>
					<el-table-column prop="add_user_number" label="可创建用户数" align="center">
					</el-table-column>
					<el-table-column prop="device_number" label="设备数量" align="center">
						<template #default="scope">
							<div class="pointer" style="color: var(--el-color-primary);"
								@click="deviceNumClick(scope.row)">{{scope.row.device_number}}</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="left" width="140">
						<template #default="scope">
							<el-button type="text" size="small" @click="custDetalis(scope.row)">详情</el-button>
							<el-button type="text" size="small" @click="custRevise(scope.row)">修改</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div class="row-me row-center flex-end1 margin-top10" v-if="tableUserInfoList.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>

		<!-- 设备列表抽屉 -->
		<el-drawer v-model="visibledrawer" :show-close="false" title="设备列表" size="50%"
			:before-close="detailHandleDrawerClose">
			<el-table ref="singleTable" :data="deviceTabList" border>
				<el-table-column type="index" label="序号" width="55" align="center">
				</el-table-column>
				<el-table-column prop="name" label="设备名称" align="center">
				</el-table-column>
				<el-table-column prop="ieme_encoded" label="IEMI编码" align="center">
				</el-table-column>
				<el-table-column prop="device_encoded" label="设备编码" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template #default="scope">
						<el-button type="text" size="small" @click="deviceDetalis(scope.row)">详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="row-me row-center flex-end1 margin-top10" v-if="deviceTabList.length>0">
				<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange1"
					:current-page.sync="pageNo1" :total="total1">
				</el-pagination>
			</div>
		</el-drawer>

		<!-- 编辑人数弹窗 -->
		<el-dialog v-model="dialogRis" title="编辑" width="30%">
			<div class="row-me divTop">
				<div class="tit">可添加用户人数</div>
				<el-input v-model="form.user_number" placeholder="请输入" />
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogRis = false" class="width100height40 margin-right10">取消</el-button>
					<el-button type="primary" @click="editOk">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		userInfoList,
		userNumberEdit,
		userInfoDetails
	} from "@/api/customerList/customerList.js"
	export default {
		data() {
			return {
				visibledrawer: false,
				dialogRis: false,
				form: {
					keyword: "",
					region1: "",
					region2: "",
					page: 1,
					pageSize: 10,
					user_number: '',
				},
				tableUserInfoList: [],
				allListNumber: "",
				detalisArr: [],
				pageNo: 1,
				total: 0,
				deviceForm: {},
				deviceTabList: [],
				pageNo1: 1,
				total1: 0,
			}
		},
		created() {
			this.CustomerTabList()
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.CustomerTabList()
			},
			// 客户列表表格
			async CustomerTabList() {
				const res = await userInfoList({
					keyword: this.form.keyword,
					page: this.pageNo,
				})
				this.tableUserInfoList = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 搜索
			customerSearch() {
				this.CustomerTabList()
			},
			deviceNumClick(row) {
				this.deviceForm = row;
				this.userDeviceWay();
				this.visibledrawer = true
			},
			userDeviceWay() {
				this.$post("/admin/userDeviceList", {
					company_id: this.deviceForm.id,
					page: this.pageNo1,
				}).then((res) => {
					if (res) {
						this.total1 = res.data.allListNumber
						this.deviceTabList = res.data.listInfo
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})

			},
			//页数改变
			handleCurrentChange1(pageNo) {
				this.pageNo1 = pageNo
				this.userDeviceWay();
			},
			// 设备详情
			deviceDetalis(row) {
				this.$router.push(`/goDeviceDetalis/${row.id}`)
			},
			// 关闭抽屉 x
			detailHandleDrawerClose() {
				this.visibledrawer = false
			},
			// 客户详情
			async custDetalis(row) {
				this.$router.push(`/custDetalis/${row.id}`)
			},
			// 编辑人数
			custRevise(row) {
				this.form.company_id = row.company_id
				this.form.user_number = row.user_number
				this.dialogRis = true
			},
			// 编辑确定按钮
			async editOk() {
				if (this.form.user_number == '' || this.form.user_number == undefined) {
					this.$message.error("可添加用户人数不能为空");
					return false;
				}
				const res = userNumberEdit({
					company_id: this.form.company_id,
					user_number: this.form.user_number
				}).then((res) => {
					if (res) {
						this.$message.success("修改成功");
						this.CustomerTabList()
						this.dialogRis = false
					}
				}).catch((error) => {
					console.log(error)
				})

			},
			// 重置按钮
			resetting() {
				this.pageNo = 1
				this.form.keyword = ""
				this.CustomerTabList()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.divTop {
		/deep/.el-input__wrapper {
			height: 40px;
		}

		.tit {
			width: 200px;
			height: 40px;
			line-height: 40px;
		}
	}

	.youzhi {
		width: 120px;
		font-size: 18px;
		float: right;
	}

	.namePut {
		width: 360px;
		height: 40px;
		margin-right: 10px;
	}

	.agains {
		margin-left: 10px !important;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-drawer__title {
		font-size: 18px;
		color: #000;
		font-weight: 400;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}

	/deep/.el-button+.el-button {
		margin-left: 0px;
	}
</style>