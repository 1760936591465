
import {
	getRequest,
	postRequest
} from "@/http/http.js"

// 客户列表表格
export const userInfoList = query => postRequest('/admin/userInfoList', query);

//客户列表表格修改人数
export const userNumberEdit = query => postRequest('/admin/userNumberEdit', query);

//客户详情
export const userInfoDetails = query => postRequest('/admin/userInfoDetails', query);

// 审核列表表格
export const auditListInfo = query => postRequest('/admin/auditListInfo', query);

// 审核列表添加人数确定
export const auditInfoExamine = query => postRequest('/admin/auditInfoExamine', query);

// 行业分类表格
export const tradeListInfo = query => postRequest('/admin/tradeListInfo', query);

// 行业分类新增编辑
export const tradeInfoAdd = query => postRequest('/admin/tradeInfoAdd', query);

// 行业分类新增删除
export const tradeInfoDel = query => postRequest('/admin/tradeInfoDel', query);